<template>
  <div class="Rule-container">
    <!-- 导航栏 -->
    <van-nav-bar
      :title="title"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{
          title
        }}</span>
      </template>
      <span
        slot="right"
        @click="$router.push('/YuebaoRecord')"
        :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
        >Record</span
      >
    </van-nav-bar>
    <!-- rollout -->
    <div class="Carry" v-if="title === 'Roll out'">
      Carry out to the balance
    </div>
    <!-- shift -->
    <div class="shift" v-else>
      <div class="left">₫</div>
      <div class="right">
        <span class="text">Balance</span>
        <span class="money">₫ 562470.00</span>
      </div>
    </div>
    <!-- cell单元格 -->
    <van-cell-group>
      <van-cell
        :title="
          title === 'Roll out'
            ? `Transfer-out amount (Each time at least ₫ 1.00)`
            : `rransfer-in amount(Each time at least ₫100.00)`
        "
      />
      <van-cell value="ALL">
        <span slot="icon" class="moneyicon">₫</span>
        <van-field
          slot="title"
          v-model="value"
          class="field"
          placeholder="0.00"
        />
      </van-cell>
    </van-cell-group>
    <!-- 按钮 -->
    <van-button class="confirm-btn" block>Confirm the transfer</van-button>
  </div>
</template>
<script>
import { transferRollout, transferShiftto } from '@/api/yuebao.js'
export default {
  name: 'Rollout',
  components: {},
  data() {
    return {
      value: '',
      title: this.$route.query.title,
    }
  },
  created() {
    // TODO:
    // this.getTransfer()
  },
  mounted() {},
  methods: {
    async getTransfer() {
      const res =
        this.$route.query.title === 'Roll out'
          ? await transferRollout(this.value ? { amount: this.value } : '')
          : await transferShiftto(this.value ? { amount: this.value } : '')
      
    },
  },
}
</script>
<style lang="less" scoped>
.Rule-container {
  /deep/ .van-nav-bar__content {
    // background-color: #fc6925;
    /deep/ .van-nav-bar__title,
    .van-nav-bar__right {
      // color: #fff;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }
  /deep/ .van-cell-group {
    width: 100%;
    .van-cell {
      &:nth-child(2) {
        padding: 0;
        height: 140px;
        padding-top: 50px;
      }
      .van-field {
        padding: 0;
        height: 80px;
      }
    }
  }
  .Carry {
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 32px;
    color: #aaa;
    padding-left: 15px;
  }
  .shift {
    height: 130px;
    width: 100%;
    background: #fff;
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .left {
      width: 66px;
      height: 66px;
      text-align: center;
      line-height: 80px;
      font-size: 35px;
      color: #fff;
      background: #fc6925;
      border-radius: 50%;
      margin: 0 25px;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      .text {
        font-size: 35px;
      }
      .money {
        font-size: 30px;
        color: #aaa;
      }
    }
  }

  /deep/ .van-cell__value {
    /deep/ span {
      display: inline-block;
      color: red;
      padding-right: 15px;
    }
    flex: 0.5;
  }
  .moneyicon {
    font-size: 70px;
    margin: 0 15px;
    margin-top: 5px;
  }
  ::placeholder {
    font-size: 60px;
    line-height: 100px;
  }
  .field {
    font-size: 60px;
  }
  .confirm-btn {
    width: 640px;
    height: 90px;
    border-radius: 20px;
    margin: 80px auto;
    background: #fc6925;
    color: #fff;
    font-size: 40px;
  }
}
</style>
